import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import { useLocation } from "react-router";
import axios from "axios";
import { Config } from "../../Config";
import Loader from "../Loader";
import Footer from "../Footer";

const SwarnimFirst = () => {

  const location = useLocation();

  const [policy, setPolicyPage] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, []);

  useEffect(() => {
    axios.get(`${Config.apiUrl}/policy`,
      {
        headers: {
          //'Authorization': 'Bearer ',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {

        const data = response.data.policy_page;
        setPolicyPage(data.policy);
        setLoading(false);
      })
      .catch(error => {
        console.error('There was a problem fetching the data:', error);

      });
  }, []);

  return (
    <>
      {loading && <Loader />}
      <Navbar />
      <div className="page-content pb-5">
        <section className="py-5 ">
          <div className="container-box py-md-2">
            <div className="text-center">
              <h2 className="fw-bold text-capitalize ">
                {policy.filter(term => term.project_name === location?.state?.project)[0]?.title}
              </h2>
              <div className="line mt-4 mb-5 mx-auto"></div>
            </div>
            <div style={{ minHeight: '40vh' }}>
              {policy
                .filter(term => term.project_name === location?.state?.project)
                .map((term, index) => (
                  <div dangerouslySetInnerHTML={{ __html: term.description }} />
                ))
              }
            </div>
          </div>
        </section>
      </div>

      {
        policy?.length > 0 &&
        <Footer />
      }
    </>
  );
};

export default SwarnimFirst;
