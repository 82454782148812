export const Config = {
    appUrl: "/",
    // apiUrl: 'https://192.168.0.2/unify/haryana/admin/api' haryanaaffordablehousingshop.com,
    apiUrl: 'https://deendayalplot.com/admin/api',
    imageUrl: 'https://deendayalplot.com/admin/assets/front/img',
    docUrl: 'https://deendayalplot.com/admin/assets/lfm/pdfs/',
    calendlyUrl: "https://calendly.com/deendayalplots2024/schedule-a-visit",
    
    // apiUrl: 'https://ztpl.net/dindayal/admin/api',
    // imageUrl: 'https://ztpl.net/dindayal/admin/assets/front/img',
    // docUrl: 'https://ztpl.net/dindayal/admin/assets/lfm/pdfs/',
    // calendlyUrl: "https://calendly.com/john-zithas/schedule-a-visit",

    // docUrl: 'https://ztpl.net/dindayal/admin/assets/lfm/pdfs/'
    //apiUrl: 'https://deendayalplot.com/admin/api',
}
