import React, { useEffect, useRef, useState } from 'react'
import Navbar from './Navbar'
import SectorInformation from './SectorInformation'
import { useLocation } from 'react-router'

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Loader from "../Components/Loader";
import axios from "axios";
import { Config } from "../Config";
import Footer from './Footer';
import { Helmet } from 'react-helmet';

const AllSectors = () => {

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, [])

    const section1Ref = useRef(null);
    const section2Ref = useRef(null);

    const [loading, setLoading] = useState(true);
    const [projectInfo, setProjectInfo] = useState([]);

    const { state } = useLocation()

    useEffect(() => {
        switch (state?.sector) {
            // case "I":
            //     section1Ref.current.scrollIntoView();
            //     break;
            case "II":
                section2Ref.current.scrollIntoView();
                break;
            // case 93:
            //     section3Ref.current.scrollIntoView();
            //     break;
            default:
                break;
        }
    }, []);

    useEffect(() => {
        const getProjectInfo = async () => {
            axios.get(`${Config.apiUrl}/autoupdate`,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then(async (response) => {
                    await axios.get(`${Config.apiUrl}/project_info`,
                        {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                        .then(response => {
                            const data = response.data.project_page;
                            setProjectInfo(data);
                            setLoading(false);
                        })
                        .catch(error => {
                            console.error('There was a problem fetching the data:', error);
                            setLoading(false);
                        });
                })
                .catch(error => {
                    console.error('There was a problem fetching the data:', error);
                    setLoading(false);
                });
        }
        getProjectInfo()

    }, []);

    const openProjects = projectInfo?.project_info?.filter(project => project.status === 1)
    const closedProjects = projectInfo?.project_info?.filter(project => project.status === 0)
    const upcomingProjects = projectInfo?.project_info?.filter(project => project.status === 2)

    console.log("openProjects", openProjects);

    return (
        <>

            <Helmet>
                <title>RERA Approved Plots for Investment in Gurugram, Sohna and Haryana</title>
                <meta name="description" content="Explore prime RERA approved plots in Gurugram, Dwarka Expressway, Sohna, Farrukhnagar, and Rohtak with DDJAY. Invest in township plots in Haryana." />
                <meta name="keywords" content="RERA approved plot in Gurugram,RERA approved plot in Dwarka Expressway,RERA approved plot in sohna,RERA approved plot in Farrukhnagar,
                RERA approved plots,RERA approved plot in Rohtak,Invest in plots,Investment in haryana plots,Township plots,Plots for investment" />
            </Helmet>
            {loading && <Loader />}
            <Navbar />

            <div className='sector-tabs bg-light-green' style={{ minHeight: '72vh' }}>
                <Tabs
                    defaultActiveKey={"open"}
                    // defaultActiveKey={openProjects?.length > 0 ? "open" : "close"}
                    transition={true}
                    id="noanim-tab-example"
                >
                    <Tab eventKey="open" title="Ongoing Projects" disabled={openProjects?.length === 0}>
                        {openProjects?.length > 0 ? (
                            openProjects.map((project, index) => (
                                <div key={index} ref={section1Ref}>
                                    <SectorInformation
                                        schemaInfo={project}
                                        projectInfo={projectInfo}
                                        index={index} />
                                </div>
                            ))
                        ) : (
                            <div className='no-open-proj'>No open projects</div>
                        )}
                    </Tab>
                    <Tab eventKey="close" title="Closed Projects" disabled={closedProjects?.length === 0}>
                        {closedProjects?.filter(project => project.status === 0)
                            .map((project, index) => (
                                <div key={index}>
                                    <SectorInformation
                                        schemaInfo={{ ...project, msg: "Sorry! Registration is Closed" }}
                                        projectInfo={projectInfo}
                                        index={index} />
                                </div>
                            ))}
                    </Tab>
                    <Tab eventKey="upcoming" title="Upcoming Projects"
                        disabled={upcomingProjects?.length === 0} >

                        {upcomingProjects
                            ?.map((project, index) => (
                                <div key={index}>
                                    <SectorInformation
                                        schemaInfo={{ ...project, msg: "Registration process is not started yet!" }}
                                        projectInfo={projectInfo}
                                        index={index}
                                    />
                                </div>
                            ))}
                    </Tab>

                </Tabs>
            </div>


            {
                projectInfo?.project_info?.length > 0 &&
                <Footer />
            }
        </>
    )
}

export default AllSectors