import React from 'react'
import { InlineWidget } from 'react-calendly'
import { Config } from '../../Config'

const CalendlyPopup = () => {
    
    const pageSettings = {
        backgroundColor: 'ffffff',
        hideEventTypeDetails: false,
        hideLandingPageDetails: false,
        primaryColor: '00a2ff',
        textColor: '4d5055',
        margin: '0'
    }

    return (
        <div>
            <InlineWidget url={Config.calendlyUrl} styles={{ height: '685px', marginTop: '-75px' }} data-cookie-policy="none" pageSettings={pageSettings} />
        </div>
    )
}

export default CalendlyPopup
