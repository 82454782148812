import React from "react"
import "../assets/css/Loader.css"

const Loader = () => {

  return (

    // <div className="preloader">
    //     <div className="spinner_wrap d-flex">
    //       <div className="spinner mx-2" />
    //       <div className="spinner mx-2" />
    //       <div className="spinner mx-2" />
    //     </div>
    //   </div>

    <div className="loader-div">
      <div class="loader1">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>
  )
}

export default Loader
