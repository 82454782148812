import React, { useState } from "react";
import { Modal } from "antd";
import CalendlyPopup from "./CalendlyPopup";
import axios from "axios";
import { Config } from "../../Config";

const MakeAppointment = ({ cta, projectName, data = {} }) => {
  const [contact, setContact] = useState(false);

  const onHandleClick = async () => {
    setContact(true)
  };

  const para_text = data?.schedule_description?.replace("$project", projectName || " Project")
  const btn_text = data?.schedule_button?.replace("$project", projectName || " Project")

  return (
    <div>
      <div
        className="cta-section bg-cta "
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4)), url(${Config.imageUrl}/${data?.schedule_bg})`,
          backgroundBlendMode: 'darken',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className="container  py-5">
          <div className="text-center text-white">
            <h2 className="display-6 fw-semibold text-capitalize">{data?.schedule_title}</h2>
            <p className="col-md-10  " dangerouslySetInnerHTML={{ __html: para_text }} />
            {/* <p>
              An intuitive online platform that will allow students to book lessons, select available instructors, and track their progress. This will also streamline your administrative processes by reducing manual scheduling.
            </p> */}
          </div>
          <div className="mt-5 text-center">
            <button className="button text-decoration-none" onClick={onHandleClick}>
              {btn_text}
            </button>
            {/* <button
              className="brochure-button border-white text-white fs-5 bg-gold"
              onClick={onHandleClick}
            >
              {projectName != undefined ? (
                <>
                  {cta?.cta_section_button_url + " for " + projectName}
                </>
              ) : (
                <>
                  {cta?.cta_section_button_url}
                </>
              )}
            </button> */}
          </div>
        </div>
      </div>

      <Modal
        title=""
        open={contact}
        onCancel={() => setContact(false)}
        className="contact-modal"
        footer={null}
        width={1100}
      >
        <div className="mt-3">
          <CalendlyPopup />
        </div>
      </Modal>

    </div >
  );
};

export default MakeAppointment;
