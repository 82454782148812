import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import "../assets/css/Home.css";
import Marquee from "react-fast-marquee";
import CTASection from "./CTASection";
import Loader from "../Components/Loader";
import axios from "axios";
import { Config } from "../Config";
import MainHomeSection from "./MainHomeSection";
import "aos/dist/aos.css";
import Aos from "aos";
import Footer from "./Footer";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import MakeAppointment from "./Calendly/MakeAppointment";

const Index = () => {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, []);

  useEffect(() => {
    Aos.init({
      once: true,
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, [])

  const [loading, setLoading] = useState(true);
  const [bannerSection, setBannerSection] = useState({});
  const [gallerySection, setGallerySection] = useState([]);
  const [ctaSection, setctaSection] = useState({});
  const [cta, setCta] = useState([]);
  const [chooseSection, setChooseSection] = useState({});
  const [choose_points, setchoose_points] = useState([]);
  const [investSection, setInvestSection] = useState([]);
  const [invest_points, setinvest_points] = useState([]);
  const [partnershipSection, setPartnershipSection] = useState({});
  const [partnership_points, setpartnership_points] = useState([]);
  const [partnership_paragraph, setpartnership_paragraph] = useState("");
  const [mainHeaderData, setMainHeaderData] = useState(null);
  const [benefitSection, setBenefitSection] = useState({});
  const [benifit_points, setbenifit_points] = useState([]);

  const [downloadData, setDownLoadData] = useState([]);
  const [downloadBg, setDownloadBg] = useState([]);

  const [calendlyBanner, setCalendlyBanner] = useState(null);


  useEffect(() => {
    axios.get(`${Config.apiUrl}/homepage`,
      {
        headers: {
          //'Authorization': 'Bearer ',
          'Content-Type': 'application/json',
          'Content-Disposition': 'attachment'
        }
      })
      .then(response => {

        const data = response.data.home_page;

        // console.log('home_page', data)
        setBannerSection(data.banner_section);
        setGallerySection(data.gallery_section);
        setctaSection({ ...data.cta_section, projects: data.project_info });
        setCta(data.cta);

        setChooseSection(data.choose_section);
        setchoose_points(
          getFinalPoints(data.choose_section.choose_description)
        )

        setInvestSection(data.invest_section);
        setinvest_points(
          getFinalPoints(data.invest_section.invest_description)
        )

        setBenefitSection(data.benefits_section);
        setbenifit_points(
          getFinalPoints(data.benefits_section.benefits_description)
        )

        setPartnershipSection(data.partnership_section);

        setDownloadBg(data.download_section);

        // Extracting the last <p> tag with its content
        const inputString = data.partnership_section.partnership_description
        var lastIndex = inputString.lastIndexOf('<p>');
        var endIndex = inputString.indexOf('</p>', lastIndex);
        var extractedString = inputString.substring(lastIndex, endIndex + 4); // Adding 4 to include '</p>'
        // console.log('extractedString : ', extractedString);

        setpartnership_points(
          getFinalPoints(inputString)
        )

        setCalendlyBanner(data.calendly)

        // console.log('inputString', inputString.replace(extractedString, ""))

        setLoading(false);
      })
      .catch(error => {
        console.error('There was a problem fetching the data:', error);
        setLoading(false);
      });
  }, []);
  // console.log("bannerSection", bannerSection);

  const getFinalPoints = (content) => {
    return (
      content
        .split('<li>').map(item => item.replace(/<\/?[^>]+(>|$)/g, "").replaceAll('&nbsp;', ' '))
        .filter(item => item != "")
    )
  }


  useEffect(() => {
    axios.get(`${Config.apiUrl}/project_info`,
      {
        headers: {
          //'Authorization': 'Bearer ',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        setMainHeaderData(response.data.project_page)

        // calculating recent added 2 projects
        const currentDate = new Date();
        // Function to calculate the difference between two dates
        const dateDiff = (date1, date2) => Math.abs(date1 - date2);

        // Calculate differences and sort
        const sortedDates =
          response.data.project_page?.project_info
            .filter(pr => pr.status === 1)
            .map(project_data => {
              const date = new Date(project_data.updated_at);
              return { date, difference: dateDiff(date, currentDate), pname: project_data.project_name };
            }).sort((a, b) => a.difference - b.difference);

        const projects_download_data = sortedDates?.slice(0, 2)?.map((project) => ({
          heading: {
            text: `Download ( ${project.pname} )`,
            bgcolor: "bg-green"
          },
          list: response.data.project_page?.project_download
            .filter(item => item.project_name === project.pname)
            .map(item => ({
              title_sm: item.document_name,
              title_lg: "",
              file: item.download_pdf
            }))
        }))

        setDownLoadData(projects_download_data)
      })
      .catch(error => {
        console.error('There was a problem fetching the data:', error);
      });
  }, []);

  const [blogData, setBlogData] = useState([]);

  useEffect(() => {
    axios.get(`${Config.apiUrl}/blogs`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        const responseData = response.data.contact_page.blog;
        //console.log("responseData",responseData);
        const mappedData = responseData.map(blog => ({
          id: blog.id,
          date: formatDate(blog.created_at),
          title: blog.title,
          content: blog.content.length > 100 ? blog.content.substring(0, 100) + '...' : blog.content,
          imgSrc: blog.main_image,
          slug: blog.slug,
          tags: blog.tags ? blog.tags.split(',').map(tag => `#${tag.trim()}\u00A0\u00A0`).join(' ') : [],
        }));
        const lastThreeBlogs = mappedData.slice(-3);

        setBlogData(lastThreeBlogs);
      })
      .catch(error => {
        console.error('There was a problem fetching the city data:', error);
      });

  }, []);

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };


  return (
    <>
      <Helmet>
        <title>Affordable Jan Awas Yojna Plots in Haryana, Farrukhnagar, Dwarka Expressway, Rohtak</title>
        <meta name="description" content="Explore affordable Deen Dayal plots under Jan Awas Yojna in Haryana. Search for 'Deen Dayal Plots Near Me' to find the best options." />
        <meta name="keywords" content="deen dayal jan awas yojna, deen dayal awas yojna plots, deen dayal jan awas yojna plots,deen dayal affordable plots,
        deen dayal plots near me,deen dayal plots,deen dayal awas yojna haryana,Deen Dayal jan Awas yojna plot in Farrukhnagar,Deen Dayal jan Awas yojna plot in Dwarka Expressway,
        Deen Dayal plot in Rohtak,Deen Dayal jan Awas yojna plot in Rohtak,jan awas plots near me,DDJAY PLOTS" />
      </Helmet>
      {
        loading && <Loader />
      }
      <div>
        <Marquee
          // speed={8} // Speed of the marquee (Optional)
          className="text-green bg-light-green head-line"
        >
          <div dangerouslySetInnerHTML={{ __html: bannerSection.hero_section_title }} />
          {/* 33% कोटा महिलाओं एवं */}
        </Marquee>
      </div>
      <Navbar />

      <MainHomeSection data={{
        banner_img: bannerSection.hero_bg,
        images: gallerySection,
        mainHeaderData: mainHeaderData,
        downloadData: downloadData,
        banner_section: bannerSection,
        bankName: bannerSection.bank_name
      }} />

      {
        downloadData.length > 0 &&
        <section className="pb-5" >
          <div className=" mx-4">
            <div className=" p-4 bg-contact"
              style={{ backgroundImage: `url(${Config.imageUrl}/${downloadBg.download_bg})` }}
            >
              {
                downloadData?.map((details) => {
                  return (
                    <div key={details?.heading?.text} className="row pt-3 pb-3">

                      <h5 className="text-white fw-bold mb-4">{details?.heading?.text}</h5>

                      {
                        details?.list?.map((list) => {
                          return (
                            <div key={list.title_sm} className="col-sm-3 mb-3">
                              <div className="card hover-effect" data-aos="flip-up" >
                                <div className="card-body">
                                  <div className="py-1">
                                    <div className="d-flex justify-content-between align-items-center">
                                      <p className="fw-bold m-0">
                                        <a href={`${Config.docUrl}${list.file}`} target="_blank" rel="noreferrer" className="text-decoration-none text-green">
                                          {list.title_sm.slice(0, 22)}..
                                        </a>
                                      </p>
                                      <a href={`${Config.docUrl}${list.file}`} target="_blank" rel="noreferrer" className="badge bg-green"><i className="bi bi-download"></i></a>
                                    </div>
                                    <div className="pt-2 border-bottom border-info"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })
                      }

                    </div>
                  )
                })
              }

            </div>
          </div>
        </section>
      }
      <CTASection data={{ ctaSection: ctaSection }} />

      <section className="py-4 pt-2">
        <div className="container-box py-md-4">
          <div className=''>
            <div className="text-center">
              <h2 className="fw-bold text-capitalize  text-green">
                {benefitSection.benefits_title}
              </h2>
              <div className="line mt-4 mb-5 mx-auto"></div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                {benifit_points.slice(0, Math.ceil(benifit_points.length / 2)).map((point, i) => {
                  return (
                    <div className="d-flex" key={i}>
                      <i className="bi bi-arrow-right me-3"></i>
                      <p className="paragraph">
                        {point}
                      </p>
                    </div>
                  )
                })}
              </div>

              <div className="col-lg-6">
                {benifit_points.slice(Math.ceil(benifit_points.length / 2)).map((point, i) => {
                  return (
                    <div className="d-flex" key={i}>
                      <i className="bi bi-arrow-right me-3"></i>
                      <p className="paragraph">
                        {point}
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>

          </div>
        </div>
      </section>

      <CTASection data={{ ctaSection: ctaSection }} />
      {/* Invest Section */}
      <section className="py-5 border-bottom  bg-light-green">
        <div className="container-box py-md-4">
          <div className="">
            {/* points */}
            <div className="">
              <h2 className="fw-bold text-green text-center text-capitalize">
                {investSection.invest_title}
              </h2>
              <div className="line mt-4 mb-5 mx-auto"></div>
            </div>

            <div className="row">

              <div className="col-lg-6">

                {
                  invest_points.slice(0, invest_points?.length / 2)
                    .map((point, i) => {
                      const content = point.split(':')
                      return (
                        <div className="d-flex" key={i}>
                          <i className="bi bi-arrow-right me-3"></i>

                          <p className="paragraph">
                            <b> {content[0]} : </b>
                            {content[1]}
                          </p>
                        </div>
                      )
                    })
                }

              </div>

              <div className="col-lg-6">

                {
                  invest_points?.slice(invest_points?.length / 2)
                    .map((point, i) => {
                      const content = point.split(':')
                      return (
                        <div className="d-flex" key={i}>
                          <i className="bi bi-arrow-right me-3"></i>

                          <p className="paragraph">
                            <b> {content[0]} : </b>
                            {content[1]}
                          </p>
                        </div>
                      )
                    })
                }

              </div>

            </div>

          </div>
        </div>
      </section>
      {/* End */}

      <CTASection data={{ ctaSection: ctaSection }} />

      {/* Development Section */}
      <section className="py-5 border-bottom">
        <div className="container-box py-md-3">
          <div className="">
            <div className="">
              <h2 className="fw-bold text-green text-center text-capitalize">
                {partnershipSection.partnership_title}
              </h2>
              <div className="line mt-4 mb-5 mx-auto"></div>
            </div>
            {/* points */}

            <div>
              <div className="row">

                <div className="col-lg-6">

                  {
                    partnership_points.slice(0, partnership_points?.length / 2)
                      .map((point, i) => {
                        const content = point.split(':')
                        return (
                          <div className="d-flex" key={i}>
                            <i className="bi bi-arrow-right me-3"></i>

                            <p className="paragraph">
                              <b> {content[0]} : </b>
                              {content[1]}
                            </p>
                          </div>
                        )
                      })
                  }

                </div>

                <div className="col-lg-6">

                  {
                    partnership_points?.slice(partnership_points?.length / 2)
                      .map((point, i) => {
                        const content = point.split(':')
                        return (
                          <div className="d-flex" key={i}>
                            <i className="bi bi-arrow-right me-3"></i>

                            <p className="paragraph">
                              <b> {content[0]} : </b>
                              {content[1]}
                            </p>
                          </div>
                        )
                      })
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End */}

      <CTASection data={{ ctaSection: ctaSection }} />

      <section className="py-5">
        <div className="container-box py-md-3">
          <div className="">
            <div className="">
              <h2 className="fw-bold text-green text-center text-capitalize">
                Blogs
              </h2>
              <div className="line mt-4 mb-5 mx-auto"></div>
            </div>

            <div className="mt-5">
              <div className="row">
                {blogData?.map((blog) => (
                  <div className="col-md-4 mb-5" key={blog.id}>
                    <div className="blog-content">
                      <div>
                        <img src={`${Config.imageUrl}/blogs/${blog.imgSrc}`} alt={`Blog ${blog.id}`}></img>
                      </div>
                      <div className="blog-section">
                        <h5>{blog.date}</h5>
                        <h4>{blog.title}</h4>
                        <div dangerouslySetInnerHTML={{ __html: blog.content }}></div>
                        <div>{blog.tags}</div>
                        <span>
                          <NavLink to={`${Config.appUrl}blogdetail/${blog.slug}`}>Read More <i className="bi bi-chevron-double-right"></i></NavLink>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

          </div>
        </div>
      </section>
      {/* <MakeAppointment data={calendlyBanner} /> */}
      <Footer />
    </>
  );
};

export default Index;
