import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import { useLocation } from "react-router-dom";
import { Config } from "../Config";
import axios from "axios";
import Loader from "../Components/Loader";
import Footer from "../Components/Footer";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const Gallery = () => {
    const location = useLocation();
    const [projectName, setProjectName] = useState('');
    const [loading, setLoading] = useState(true);
    const [gallery, setGallery] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [imagesPreloaded, setImagesPreloaded] = useState(false);

    useEffect(() => {
        const savedProjectName = location.state?.project || localStorage.getItem('projectName');
        if (savedProjectName) {
            setProjectName(savedProjectName);
            localStorage.setItem('projectName', savedProjectName);
        }
    }, [location.state]);

    useEffect(() => {
        if (projectName) {
            axios.get(`${Config.apiUrl}/project_info`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                const data = response.data.project_page.gallery;
                setGallery(data?.filter(p => p.project_name === projectName));
                setLoading(false);
            })
            .catch(error => {
                console.error('There was a problem fetching the data:', error);
                setLoading(false);
            });
        }
    }, [projectName]);

    const preloadImages = async () => {
        const images = gallery.map(item => `${Config.imageUrl}/gallery/${item.image}`);
        await Promise.all(images.map(imageUrl => new Promise((resolve, reject) => {
            const img = new Image();
            img.src = imageUrl;
            img.onload = resolve;
            img.onerror = reject;
        })));
        setImagesPreloaded(true);
    };

    const handleImageClick = (index) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };

    useEffect(() => {
        if (isOpen && !imagesPreloaded) {
            preloadImages();
        }
    }, [isOpen]);

    const handleLightboxClose = () => {
        setIsOpen(false);
        setImagesPreloaded(false);
    };

    return (
        <>
            {loading && <Loader />}
            <Navbar />
            <div className="gallery-section">
                <h2>Gallery Section - {projectName}</h2>
                <div className="gallery-container">
                    {gallery.map((item, index) => (
                        <div className="gallery-item" key={index} onClick={() => handleImageClick(index)}>
                            <img src={`${Config.imageUrl}/gallery/${item.image}`} alt={'Gallery Image'} />
                        </div>
                    ))}
                </div>
            </div>

            {isOpen && (
                <Lightbox
                    mainSrc={`${Config.imageUrl}/gallery/${gallery[photoIndex].image}`}
                    nextSrc={`${Config.imageUrl}/gallery/${gallery[(photoIndex + 1) % gallery.length].image}`}
                    prevSrc={`${Config.imageUrl}/gallery/${gallery[(photoIndex + gallery.length - 1) % gallery.length].image}`}
                    onCloseRequest={handleLightboxClose}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + gallery.length - 1) % gallery.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % gallery.length)}
                />
            )}
            <Footer />
        </>
    );
};

export default Gallery;
