import React from 'react'
import { Link } from 'react-router-dom'
import { Config } from '../../Config'

const ListBox = ({ data, highlightText }) => {


    return (
        <>
            {data?.heading?.text != undefined ? (
                <div className='mb-4'>
                    <h6 className={`px-4 py-3 rounded text-white text-center listbox-heading ${data?.heading?.bgcolor}`}>
                        {data?.heading?.text}
                    </h6>

                    <div className="mt-1 px-3 py-3 bg-light-green rounded listbox-content">
                        {
                            data?.list?.map((info, index) => {
                                return (
                                    <div key={index} className={`${index !== data?.list?.length-1 && 'border-bottom'} py-2 d-flex align-items-center `}>
                                        <i className={`bi bi-check-all fs-5 me-2 ${data?.heading.bgcolor.replace("bg", "text")}`}></i>
                                        <div>
                                            <span >
                                                {
                                                    info.file ?
                                                        <a className='text-dark text-decoration-none ps-0'
                                                            href={info.file ? `${Config.docUrl}${info.file}` : "#"} target='_blank'>
                                                            {info.title_sm}
                                                            <span className='text-animated d-inline rounded ms-1 badge lh-sm' style={{ fontSize: "12px" }}> <i className='bi bi-arrow-left me-1'></i> Click here </span>
                                                        </a>
                                                        : info.title_sm
                                                }
                                            </span>
                                            <p className='mb-0'>
                                                {
                                                    info.url ? <Link to={info.url}
                                                        state={{ index: index, project: info.name }}
                                                        className='text-dark text-decoration-none ps-0'>
                                                        {info.title_lg}
                                                        <span className='text-animated d-inline rounded ms-1 badge lh-sm' style={{ fontSize: "12px" }}> {highlightText &&
                                                            <>
                                                                <i className='bi bi-arrow-left fs-6 me-1'></i> Registration Now
                                                            </>
                                                        }  </span>
                                                    </Link>
                                                        : info.title_lg
                                                }
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {data?.list?.length === 0 ? (
                            <a className='mb-0 text-dark text-decoration-none d-block text-center'>Details Not Available!</a>
                        ) : null}
                    </div>

                </div>
            ) : null}
        </>
    )
}

export default ListBox