import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Index from "./Components/Index";
import Contact from "./Components/Contact";
// import GoreGao from "./Components/GoreGao";
import RegisterForm from "./Components/RegisterForm";
import Swarnim1 from "./Components/Sectors/Swarnim1";
import AllSectors from "./Components/AllSectors";
import SwarnimFirst from "./Components/Policy/SwarnimFirst";
import {Config} from "./Config";
import AffordablePolicy from "./Components/Policy/AffordablePolicy";
import AboutUs from "./Components/AboutUs";
import Gallery from "./Components/Gallery";
import Refund from "./Components/Refund";
import BlogDetail from "./Components/BlogDetail";
import Blogs from "./Components/Blogs";

function App() {
  return (
    
    <div className="">
      <Router>
        <ToastContainer theme="colored" />
        <Routes>
          <Route path={`${Config.appUrl}`} Component={Index} />
          <Route path={`${Config.appUrl}get-in-touch`} Component={Contact} />

          <Route path={`${Config.appUrl}all-projects`} Component={AllSectors} />

          <Route path={`${Config.appUrl}projectdetails/:projectname`} Component={Swarnim1} />

          <Route path={`${Config.appUrl}about-us`} Component={AboutUs} />
          <Route path={`${Config.appUrl}register`} Component={RegisterForm} />

          <Route path={`${Config.appUrl}refund-policy/:policyName`} Component={SwarnimFirst} />
          <Route path={`${Config.appUrl}affordable/:policyName`} Component={AffordablePolicy} />

          <Route path={`${Config.appUrl}gallery/:projectname`} Component={Gallery} />

          <Route path={`${Config.appUrl}refund/:slug`} Component={Refund} />

          <Route path={`${Config.appUrl}blogdetail/:slug`} Component={BlogDetail} />

          <Route path={`${Config.appUrl}blogs`} Component={Blogs} />
          
        </Routes>
      </Router>
    </div>
  );
}

export default App;
