import React, { useEffect, useState, useRef } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useSearchParams, useParams, Redirect, NavLink } from "react-router-dom";
import axios from "axios";
import { Config } from "../Config";
import swal from "sweetalert";
import Loader from "./Loader";
import NotFound from "./NotFound";
import img from "../assets/Images/success-icon.png"
import warn from "../assets/Images/images.png"

const Refund = () => {

    const { slug } = useParams();

    const [expired, setExpired] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [pancard, setPanCard] = useState(null);
    const [aadharcard, setAadharCard] = useState(null);
    const [cheque, setCheque] = useState(null);
    const [payment, setPayment] = useState(null);
    const [bankStat, setBnkstat] = useState(null);
    const [letter, setLetter] = useState(null);

    const [loading, setLoading] = useState(false);

    const [notFound, setNotFound] = useState(false);

    const [errors, setErrors] = useState({
        pancard: "",
        aadharcard: "",
        cheque: "",
        payment: "",
        bankStat: "",
        letter: "",
    })

    const [statusData, setStatusData] = useState([]);
    const [doc_data, setDocData] = useState([]);

    const pancardRef = useRef(null);
    const aadharcardRef = useRef(null);
    const chequeRef = useRef(null);
    const paymentRef = useRef(null);
    const bnkstatRef = useRef(null);
    const letterRef = useRef(null);

    const handleFileChange = (setter) => (e) => {
        setter(e.target.files[0]);
    };

    useEffect(() => {
        setLoading(true);
        axios.get(`${Config.apiUrl}/refund_status`, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                const responseData = response.data;

                if (Array.isArray(responseData.data)) {

                    const matchingStatus = responseData.data.find(item => item.junk_link === slug);
                    if (matchingStatus) {
                        setStatusData(matchingStatus);
                        setDocData(matchingStatus);
                    }
                    else {
                        //setNotFound(true);
                        setExpired(true);
                    }

                } else {
                    console.error('The response data.data is not an array:', responseData.data);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('There was a problem fetching the city data:', error);
            });

    }, [slug]);

    const is30DaysPassed = () => {
        if (!statusData || !statusData.junk_date) {
            return false; // If statusData or junk_date is not available, return false
        }
        const thirtyDaysInMillis = 30 * 24 * 60 * 60 * 1000; // Convert 30 days to milliseconds
        const currentDate = new Date(); // Current date
        const junkDate = new Date(statusData.junk_date); // Convert junk_date string to Date object
        const differenceInMillis = currentDate - junkDate; // Difference in milliseconds
        return differenceInMillis >= thirtyDaysInMillis; // Return true if 30 days have passed, else false
    };

    const statusCheck = () => {
        if (!statusData || !statusData.junk_status) {
            return false;
        }
        return statusData.junk_status === 1;
    }

    useEffect(() => {
        if (is30DaysPassed()) {
            //console.log("30 days have passed since junk_date:", statusData.junk_date);
            setExpired(true);
        }
        else if (statusCheck()) {
            setSubmitted(true);
        }
    }, [statusData]);

    const handleValidation = () => {
        const newErrors = {};

        if (!pancard) {
            newErrors.pancard = "Pan Card Copy is required.";
        }

        if (!aadharcard) {
            newErrors.aadharcard = "Aadhar Card Copy is required.";
        }

        if (!cheque) {
            newErrors.cheque = "Photo of the Cheque towards refund is required.";
        }

        if (!payment) {
            newErrors.payment = "Payment Details is required.";
        }

        if (!bankStat) {
            newErrors.bankStat = "Bank Statement is required.";
        }

        if (!letter) {
            newErrors.letter = "Handwritten Letter for Cancellation is required.";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (submitted) {
            if (!pancard && !aadharcard && !cheque && !payment && !bankStat && !letter) {
                setLoading(true);
                try {
                    const formData = new FormData();
                    formData.append("register_id", doc_data.id);
                    if (pancard) formData.append("pan_document", pancard);
                    if (aadharcard) formData.append("aadhar_document", aadharcard);
                    if (cheque) formData.append("cheque_document", cheque);
                    if (payment) formData.append("payment_document", payment);
                    if (bankStat) formData.append("bank_document", bankStat);
                    if (letter) formData.append("letter_document", letter);

                    const res = await axios.post(
                        `${Config.apiUrl}/refund_document`,
                        formData,
                        {
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "multipart/form-data",
                            },
                        }
                    );

                    if (res.data.status === 1) {
                        const docData = res.data.company;
                        swal("", "Document Uploaded successfully!", "success").then(() => {
                            setDocData({
                                pan_document: docData.pan_document,
                                aadhar_document: docData.aadhar_document,
                                bank_document: docData.bank_document,
                                cheque_document: docData.cheque_document,
                                letter_document: docData.letter_document,
                                payment_document: docData.payment_document
                            });
                            window.location.reload();
                        });
                        // Clear input fields
                        setAadharCard(null);
                        setPanCard(null);
                        setCheque(null);
                        setPayment(null);
                        setBnkstat(null);
                        setLetter(null);

                        pancardRef.current.value = "";
                        aadharcardRef.current.value = "";
                        chequeRef.current.value = "";
                        paymentRef.current.value = "";
                        bnkstatRef.current.value = "";
                        letterRef.current.value = "";
                    }
                    setLoading(false);
                }
                catch (error) {
                    console.log(error);
                    swal("", "Something Went Wrong", "error");
                    setLoading(false);
                }
            }
            else {
                swal({
                    title: "Are you sure?",
                    text: "You want to Upload Document Again?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                    .then(async (willProceed) => {
                        if (willProceed) {
                            setLoading(true);
                            try {
                                const formData = new FormData();
                                formData.append("register_id", doc_data.id);
                                if (pancard) formData.append("pan_document", pancard);
                                if (aadharcard) formData.append("aadhar_document", aadharcard);
                                if (cheque) formData.append("cheque_document", cheque);
                                if (payment) formData.append("payment_document", payment);
                                if (bankStat) formData.append("bank_document", bankStat);
                                if (letter) formData.append("letter_document", letter);

                                const res = await axios.post(
                                    `${Config.apiUrl}/refund_document`,
                                    formData,
                                    {
                                        headers: {
                                            Accept: "application/json",
                                            "Content-Type": "multipart/form-data",
                                        },
                                    }
                                );

                                //console.log("Post Api Response", res.data);

                                if (res.data.status === 1) {
                                    const docData = res.data.company;
                                    swal("", "Document Uploaded successfully!", "success").then(() => {
                                        setDocData({
                                            pan_document: docData.pan_document,
                                            aadhar_document: docData.aadhar_document,
                                            bank_document: docData.bank_document,
                                            cheque_document: docData.cheque_document,
                                            letter_document: docData.letter_document,
                                            payment_document: docData.payment_document
                                        });
                                        window.location.reload();
                                    });
                                    // Clear input fields
                                    setAadharCard(null);
                                    setPanCard(null);
                                    setCheque(null);
                                    setPayment(null);
                                    setBnkstat(null);
                                    setLetter(null);

                                    pancardRef.current.value = "";
                                    aadharcardRef.current.value = "";
                                    chequeRef.current.value = "";
                                    paymentRef.current.value = "";
                                    bnkstatRef.current.value = "";
                                    letterRef.current.value = "";
                                }
                                setLoading(false);
                            }
                            catch (error) {
                                console.log(error);
                                swal("", "Something Went Wrong", "error");
                                setLoading(false);
                            }
                        }
                        else {
                            return;
                        }
                    });
            }
        }
        else {
            if (handleValidation()) {
                setLoading(true);
                try {
                    const formData = new FormData();
                    formData.append("pan_document", pancard);
                    formData.append("register_id", statusData.id);
                    formData.append("aadhar_document", aadharcard);
                    formData.append("cheque_document", cheque);
                    formData.append("payment_document", payment);
                    formData.append("bank_document", bankStat);
                    formData.append("letter_document", letter);

                    const res = await axios.post(
                        `${Config.apiUrl}/refund_document`,
                        formData,
                        {
                            headers: {
                                Accept: "application/json",
                                "Content-Type": "multipart/form-data",
                            },
                        }
                    );

                    //console.log("Post Api Response", res.data);

                    if (res.data.status === 1) {
                        swal("", "Document Uploaded successfully!", "success").then(() => {
                            setSubmitted(true);
                            window.location.reload();
                        });
                        setAadharCard(null);
                        setPanCard(null);
                        setCheque(null);
                        setPayment(null);
                        setBnkstat(null);
                        setLetter(null);

                        pancardRef.current.value = "";
                        aadharcardRef.current.value = "";
                        chequeRef.current.value = "";
                        paymentRef.current.value = "";
                        bnkstatRef.current.value = "";
                        letterRef.current.value = "";
                    }
                    setLoading(false);
                }
                catch (error) {
                    console.log(error);
                    swal("", "Something Went Wrong", "error");
                    setLoading(false);
                }
            }
        }
    }

    return (
        <>
            {loading && <Loader />}
            <Navbar />

            <div>

                {expired ? (
                    <div>
                        <div className="refund1">
                            <h3>Refund Page</h3>
                        </div>
                        <div className="expired-text">
                            <div>
                                <h3><b>Link is not valid any more</b></h3>
                                <br />
                                <h4>Oops! This URL is not valid anymore</h4>

                                <div className="buttondiv">
                                    <NavLink to={`${Config.appUrl}`}>Go to homepage</NavLink>
                                </div>
                            </div>
                            <div className="img-div">
                                <img src={warn}></img>
                            </div>
                        </div>
                    </div>
                ) : submitted ? (
                    // <div className="submitted">
                    //     <img src={img}></img>
                    //     <h3 className="mt-4">
                    //         Documents Uploaded Succesfully!
                    //     </h3>
                    //     <div className="mt-5 button1">
                    //         <NavLink to={`${Config.appUrl}`}>Go to Homepage</NavLink>
                    //     </div>
                    // </div>
                    <div className="bg-light-green1">
                        <div className="refund">
                            <h3>Refund Page</h3>
                        </div>
                        <div className="proof">
                        <div className="proof-section">
                            <div className="row justify-content-around">
                                <div className="col-sm-3 mb-5 mx-4 align-content-center">
                                    <div>
                                        <label htmlFor="" className="text-white mb-2">
                                            Copy of PAN Card
                                            {/* <span className="text-red">*</span> */}
                                        </label>
                                        <input
                                            type="file"
                                            placeholder="Enter Applicant Name"
                                            className="form-field border border-2 text-white"
                                            onChange={handleFileChange(setPanCard)}
                                            ref={pancardRef}
                                        />
                                        {errors.pancard && (
                                            <span className="text-danger">
                                                {errors.pancard}
                                            </span>
                                        )}
                                    </div>

                                </div>
                                <div className="col-sm-1 mb-4 me-5 align-content-center">
                                    <div className="image-box text-ellipsis">
                                        <a className="text-green" href={`${Config.docUrl}${doc_data.pan_document}`} target="_blank" download style={{ textDecoration: 'none' }}>
                                            <img src={`${Config.docUrl}${doc_data.pan_document}`} alt={doc_data.pan_document} className="img-fluid" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-sm-3 mb-5 mx-4 align-content-center">
                                    <div>
                                        <label htmlFor="" className="text-white mb-2">
                                            Copy of Aadhar Card
                                            {/* <span className="text-red">*</span> */}
                                        </label>
                                        <input
                                            type="file"
                                            placeholder="Enter Applicant Name"
                                            className="form-field border border-2 text-white"
                                            onChange={handleFileChange(setAadharCard)}
                                            ref={aadharcardRef}
                                        />
                                        {errors.aadharcard && (
                                            <span className="text-danger">
                                                {errors.aadharcard}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-1 mb-4 me-5 align-content-center">
                                    <div className="image-box text-ellipsis">
                                        <a className="text-green" href={`${Config.docUrl}${doc_data.aadhar_document}`} target="_blank" download style={{ textDecoration: 'none' }}>
                                            <img src={`${Config.docUrl}${doc_data.aadhar_document}`} alt={doc_data.aadhar_document} className="img-fluid" /></a>
                                    </div>
                                </div>
                                <div className="col-sm-3 mb-5 mx-4 align-content-center">
                                    <div>
                                        <label htmlFor="" className="text-white mb-2">
                                            Photo of the Cheque towards refund
                                            {/* <span className="text-red">*</span> */}
                                        </label>
                                        <input
                                            type="file"
                                            placeholder="Enter Applicant Name"
                                            className="form-field border border-2 text-white"
                                            onChange={handleFileChange(setCheque)}
                                            ref={chequeRef}
                                        />
                                        {errors.cheque && (
                                            <span className="text-danger">
                                                {errors.cheque}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-1 mb-4 me-5 align-content-center">
                                    <div className="image-box text-ellipsis">
                                        <a className="text-green" href={`${Config.docUrl}${doc_data.cheque_document}`} target="_blank" download style={{ textDecoration: 'none' }}>
                                            <img src={`${Config.docUrl}${doc_data.cheque_document}`} alt={doc_data.cheque_document} className="img-fluid" /></a>
                                    </div>
                                </div>
                                <div className="col-sm-3 mb-5 mx-4 align-content-center">
                                    <div>
                                        <label htmlFor="" className="text-white mb-2">
                                            Details of payment
                                            {/* <span className="text-red">*</span> */}
                                        </label>
                                        <input
                                            type="file"
                                            placeholder="Enter Applicant Name"
                                            className="form-field border border-2 text-white"
                                            onChange={handleFileChange(setPayment)}
                                            ref={paymentRef}
                                        />
                                        {errors.payment && (
                                            <span className="text-danger">
                                                {errors.payment}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-1 mb-4 me-5 align-content-center">
                                    <div className="image-box text-ellipsis">
                                        <a className="text-green" href={`${Config.docUrl}${doc_data.payment_document}`} target="_blank" download style={{ textDecoration: 'none' }}>
                                            <img src={`${Config.docUrl}${doc_data.payment_document}`} alt={doc_data.payment_document} className="img-fluid" /></a>
                                    </div>
                                </div>
                                <div className="col-sm-3 mb-5 mx-4 align-content-center">
                                    <div>
                                        <label htmlFor="" className="text-white mb-2">
                                            Bank Statement
                                            {/* <span className="text-red">*</span> */}
                                        </label>
                                        <input
                                            type="file"
                                            placeholder="Enter Applicant Name"
                                            className="form-field border border-2 text-white"
                                            onChange={handleFileChange(setBnkstat)}
                                            ref={bnkstatRef}
                                        />
                                        {errors.bankStat && (
                                            <span className="text-danger">
                                                {errors.bankStat}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-1 mb-4 me-5 align-content-center">
                                    <div className="image-box text-ellipsis">
                                        <a className="text-green" href={`${Config.docUrl}${doc_data.bank_document}`} target="_blank" download style={{ textDecoration: 'none' }}>
                                            <img src={`${Config.docUrl}${doc_data.bank_document}`} alt={doc_data.bank_document} className="img-fluid" /></a>
                                    </div>
                                </div>
                                <div className="col-sm-3 mb-5 mx-4 align-content-center">
                                    <div>
                                        <label htmlFor="" className="text-white mb-2">
                                            Handwritten Letter for Cancellation with client Signature
                                            {/* <span className="text-red">*</span> */}
                                        </label>
                                        <input
                                            type="file"
                                            placeholder="Enter Applicant Name"
                                            className="form-field border border-2 text-white"
                                            onChange={handleFileChange(setLetter)}
                                            ref={letterRef}
                                        />
                                        {errors.letter && (
                                            <span className="text-danger">
                                                {errors.letter}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-1 mb-4 me-5 align-content-center">
                                    <div className="image-box text-ellipsis">
                                        <a className="text-green" href={`${Config.docUrl}${doc_data.letter_document}`} target="_blank" download style={{ textDecoration: 'none' }}>
                                            <img src={`${Config.docUrl}${doc_data.letter_document}`} alt={doc_data.letter_document} className="img-fluid" /></a>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-3">
                                <button className="button" onClick={handleSubmit}>
                                    Submit
                                </button>
                            </div>
                        </div>
                        </div>
                    </div>
                ) : (
                    <div className="bg-light-green1">
                        <div className="refund">
                            <h3>Refund Page</h3>
                        </div>
                        <div className="proof">
                        <div className="proof-section">
                            <div className="row justify-content-around">
                                <div className="col-sm-4 mb-5 mx-4 align-content-center">
                                    <div>
                                        <label htmlFor="" className="text-white mb-2">
                                            Copy of PAN Card<span className="text-red">*</span>
                                        </label>
                                        <input
                                            type="file"
                                            placeholder="Enter Applicant Name"
                                            className="form-field border border-2 text-white"
                                            onChange={handleFileChange(setPanCard)}
                                            ref={pancardRef}
                                        />

                                        {submitted ? (
                                            <div className="sub_doc">{doc_data.pan_document}</div>
                                        ) : (
                                            errors.pancard && (
                                                <span className="text-danger">
                                                    {errors.pancard}
                                                </span>
                                            )
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-4 mb-5 mx-4 align-content-center">
                                    <div>
                                        <label htmlFor="" className="text-white mb-2">
                                            Copy of Aadhar Card<span className="text-red">*</span>
                                        </label>
                                        <input
                                            type="file"
                                            placeholder="Enter Applicant Name"
                                            className="form-field border border-2 text-white"
                                            onChange={handleFileChange(setAadharCard)}
                                            ref={aadharcardRef}
                                        />
                                        {submitted ? (
                                            <div className="sub_doc">{doc_data.aadhar_document}</div>
                                        ) : (
                                            errors.aadharcard && (
                                                <span className="text-danger">
                                                    {errors.aadharcard}
                                                </span>
                                            )
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-4 mb-5 mx-4 align-content-center">
                                    <div>
                                        <label htmlFor="" className="text-white mb-2">
                                            Photo of the Cheque towards refund<span className="text-red">*</span>
                                        </label>
                                        <input
                                            type="file"
                                            placeholder="Enter Applicant Name"
                                            className="form-field border border-2 text-white"
                                            onChange={handleFileChange(setCheque)}
                                            ref={chequeRef}
                                        />
                                        {submitted ? (
                                            <div className="sub_doc">{doc_data.cheque_document}</div>
                                        ) : (
                                            errors.cheque && (
                                                <span className="text-danger">
                                                    {errors.cheque}
                                                </span>
                                            )
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-4 mb-5 mx-4 align-content-center">
                                    <div>
                                        <label htmlFor="" className="text-white mb-2">
                                            Details of payment<span className="text-red">*</span>
                                        </label>
                                        <input
                                            type="file"
                                            placeholder="Enter Applicant Name"
                                            className="form-field border border-2 text-white"
                                            onChange={handleFileChange(setPayment)}
                                            ref={paymentRef}
                                        />
                                        {submitted ? (
                                            <div className="sub_doc">{doc_data.payment_document}</div>
                                        ) : (
                                            errors.payment && (
                                                <span className="text-danger">
                                                    {errors.payment}
                                                </span>
                                            )
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-4 mb-5 mx-4 align-content-center">
                                    <div>
                                        <label htmlFor="" className="text-white mb-2">
                                            Bank Statement<span className="text-red">*</span>
                                        </label>
                                        <input
                                            type="file"
                                            placeholder="Enter Applicant Name"
                                            className="form-field border border-2 text-white"
                                            onChange={handleFileChange(setBnkstat)}
                                            ref={bnkstatRef}
                                        />
                                        {submitted ? (
                                            <div className="sub_doc">{doc_data.bank_document}</div>
                                        ) : (
                                            errors.bankStat && (
                                                <span className="text-danger">
                                                    {errors.bankStat}
                                                </span>
                                            )
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-4 mb-5 mx-4 align-content-center">
                                    <div>
                                        <label htmlFor="" className="text-white mb-2">
                                            Handwritten Letter for Cancellation with client Signature<span className="text-red">*</span>
                                        </label>
                                        <input
                                            type="file"
                                            placeholder="Enter Applicant Name"
                                            className="form-field border border-2 text-white"
                                            onChange={handleFileChange(setLetter)}
                                            ref={letterRef}
                                        />
                                        {submitted ? (
                                            <div className="sub_doc">{doc_data.letter_document}</div>
                                        ) : (
                                            errors.letter && (
                                                <span className="text-danger">
                                                    {errors.letter}
                                                </span>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="mt-3">
                                <button className="button" onClick={handleSubmit}>
                                    Submit
                                </button>
                            </div>
                        </div>
                        </div>
                    </div>
                )
                }

            </div>

        </>
    )
}
export default Refund