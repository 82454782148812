
import Navbar from './Navbar'
import Footer from './Footer'
import axios from 'axios'
import React, { useEffect, useState } from "react";
import Loader from './Loader';
import { Config } from '../Config';
import { Helmet } from 'react-helmet';

const AboutUs = () => {

    const [loading, setLoading] = useState(true);
    const [about, setAboutContent] = useState([]);
    const [proj, setProjDetails] = useState([]);

    useEffect(() => {
        axios.get(`${Config.apiUrl}/homepage`,
            {
                headers: {
                    //'Authorization': 'Bearer ',
                    'Content-Type': 'application/json',
                    'Content-Disposition': 'attachment'
                }
            })
            .then(response => {
                setAboutContent(response.data.home_page.about_section);
                setProjDetails(response.data.home_page);
                setLoading(false);
            })
            .catch(error => {
                console.error('There was a problem fetching the data:', error);
                setLoading(false);
            });
    }, []);

    return (
        <>
            <Helmet>
                <title>Affordable Plots in Haryana, Gurugram, Jhajjar</title>
                <meta name="description" content="Discover affordable plots under Deen Dayal Awas Yojna in Haryana. It offers best plots in Gurugram, Jhajjar, Baldi, Rohtak, Bahadurgarh, and Dwarka Expressway." />
                <meta name="keywords" content="plots under deen dayal awas yojna,jan awas yojna haryana,jan awas yojna gurugram,jan awas yojna jhajjar,Affordable plots in haryana,Affordable plots in jhajjar,
        Plot in Dwarka Expressway,Plot in Gurugram,Plot in baldi,Plot in bahadurgarh,Plot in Rohtak,Plot,Affordabale Plot,Plots in haryana,Haryana plots,Best plots in jhajjar,Jhajjar plots" />
            </Helmet>
            {
                loading && <Loader />
            }
            <Navbar />

            <section className='py-5'>
                <div className='container-box py-md-4 '>
                    <div className="row justify-content-around align-items-center flex-md-row flex-column-reverse">
                        <div className="col-md-7">
                            <h2 className='fw-bold display-6 text-green'>{about.about_title}</h2>

                            <div dangerouslySetInnerHTML={{ __html: about.about_subtitle }} />
                        </div>
                        <div className="col-md-4 mb-md-5 mb-5">
                            <div>
                                <img src={`${Config.imageUrl}/${about.about_img}`} alt="about" className='img-fluid' />
                            </div>

                            <div className='d-flex justify-content-center '>
                                <div className='w-50 text-center py-2 bg-green'>
                                    <div className='fs-6 text-white text-uppercase py-2 px-3 d-flex justify-content-between'>
                                        <span>OnGoing</span>  <span className='fw-bold'>{proj.ongoing}</span>
                                    </div>
                                </div>
                                <div className='w-50 text-center py-2 bg-blue'>
                                    <div className='fs-6 text-white text-uppercase py-2 px-3 d-flex justify-content-between align-items-center'>
                                        <span>Finished</span>  <span className='fw-bold'>{proj.close}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default AboutUs