import React, { useEffect, useState } from 'react'
import Navbar from "./Navbar";
import axios from 'axios';
import swal from 'sweetalert';
import Loader from "../Components/Loader";
import { Config } from "../Config";
import Footer from './Footer';
import { Helmet } from 'react-helmet';
import MakeAppointment from './Calendly/MakeAppointment';

const Contact = () => {

    const [name, setName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [contactSection, setContactSection] = useState({});
    const [contactBg, setContactbg] = useState('');

    const [calendlyBanner, setCalendlyBanner] = useState(null);

    const [errors, setErrors] = useState({
        name: "",
        emailAddress: "",
        phoneNumber: "",
        message: ""
    })

    useEffect(() => window.scrollTo(0, 0), [])

    useEffect(() => {

        axios.get(`${Config.apiUrl}/homepage`,
            {
                headers: {
                    //'Authorization': 'Bearer ',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {

                const data = response.data?.home_page?.contactus_section;
                //setContactSection(data.contact_section);
                setContactbg(data);

                setCalendlyBanner(response.data?.home_page?.calendly)
            })
            .catch(error => {
                console.error('There was a problem fetching the data:', error);

            });

        axios.get(`${Config.apiUrl}/contactpage`,
            {
                headers: {
                    //'Authorization': 'Bearer ',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {

                const data = response.data.contact_page;
                setContactSection(data.contact_section);
                setLoading(false);
            })
            .catch(error => {
                console.error('There was a problem fetching the data:', error);

            });
    }, []);

    // window close functionality =========================================
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // Show custom popup warning only if the form is not submitted
            const message = 'Your data will be lost if you don\'t save it!';
            event.preventDefault();
            event.returnValue = 'Your data will be lost if you don\'t save it!'; // Necessary for Chrome
        };

        // Assign event listener directly to window.onbeforeunload
        window.onbeforeunload = handleBeforeUnload;

        // Cleanup function to remove the event listener
        return () => {
            window.onbeforeunload = null; // Remove event listener
        };
    }, []);

    // validations for first form
    const handleValidation = () => {

        const newErrors = {};

        if (!name) {
            newErrors.name = 'Name is required';
        }

        if (!message) {
            newErrors.message = 'Message is required';
        }

        if (!emailAddress) {
            newErrors.emailAddress = 'Email is required';
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)) {
            newErrors.emailAddress = 'Invalid email format';
        }

        if (!phoneNumber) {
            newErrors.phoneNumber = 'Phone number is required';
        } else if (phoneNumber.length > 10 || phoneNumber.length < 10) {
            newErrors.phoneNumber = 'Phone number must be of 10 digits';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }
    // ===========================

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (handleValidation()) {
            setLoading(true)
            try {
                const formData = new FormData()

                formData.append('name', name)
                formData.append('phone', phoneNumber)
                formData.append('email', emailAddress)
                formData.append('message', message)

                const res = await axios.post(`${Config.apiUrl}/contact`, formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    }
                })
                if (res.data.message == "Message Succesfully sent...") {
                    swal("", "Message Sent Successfully", "success")
                    setName('');
                    setEmailAddress('');
                    setPhoneNumber('');
                    setMessage('');
                }
                setLoading(false)

            } catch (error) {
                console.log(error)
                swal("", "Something Went Wrong", "error")
                setLoading(false)
            }
        }
    }

    return (
        <div>
            <Helmet>
                <title>Affordable Freehold Plots in Haryana With Deen Dayal Plots</title>
                <meta name="description" content="Explore affordable freehold plots in Haryana under the Deen Dayal Housing Scheme. Know more about upcoming housing schemes and find the perfect plot near you." />
                <meta name="keywords" content="Freehold plots in haryana,deen dayal housing scheme,deen dayal plot scheme,deen dayal plot scheme near me,housing scheme near me,
                housing scheme,affordable housing scheme,upcoming housing schemes" />
            </Helmet>
            {loading && <Loader />}
            <Navbar />
            <section className='py-5 bg-contact' style={{
                minHeight: "100vh",
                backgroundImage: `linear-gradient(#222222a5, #222222a5),url(${Config.imageUrl}/${contactBg.contactus_bg})`
            }}>
                <div className='container-box py-md-4'>
                    <div className='row flex-md-row flex-column-reverse align-items-md-start align-items-center'>
                        <div className="col-md-6 col-11 p-md-5 p-4 bg-white shadow-lg rounded-4 mt-md-0 mt-4" >
                            <form onSubmit={handleSubmit} className='py-3'>
                                <h2 className="fw-bold mb-4 text-green">Connect With US</h2>
                                <div className=" bd-form-control">
                                    <div className="col-12 mb-4">
                                        <label htmlFor="name">Full Name</label>
                                        <input type="text"
                                            className={`form-field bg-light py-3 border border-2`}
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            placeholder="Enter Full Name" />
                                        {
                                            errors.name && <span className='text-danger'>{errors.name}</span>
                                        }
                                    </div>

                                    <div className="col-12 mb-4">
                                        <label htmlFor="name">Phone Number</label>
                                        <input type="text"
                                            className={`form-field bg-light py-3 border border-2`}
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                            placeholder="Enter Phone Number" />
                                        {
                                            errors.phoneNumber && <span className='text-danger'>{errors.phoneNumber}</span>
                                        }
                                    </div>

                                    <div className="col-12 mb-4">
                                        <label htmlFor="name">Email Address</label>
                                        <input type="text"
                                            className={`form-field bg-light py-3 border border-2`}
                                            value={emailAddress}
                                            onChange={(e) => setEmailAddress(e.target.value)}
                                            placeholder="Enter Email" />
                                        {
                                            errors.emailAddress && <span className='text-danger'>{errors.emailAddress}</span>
                                        }
                                    </div>

                                    <div className="col-12 mb-4">
                                        <label htmlFor="name">Message</label>
                                        <input type="text"
                                            className={`form-field bg-light py-3 border border-2`}
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            placeholder="Enter Message" />
                                        {
                                            errors.message && <span className='text-danger'>{errors.message}</span>
                                        }
                                    </div>
                                </div>

                                <div className="mt-5">
                                    <button className='button bg-green'>
                                        {loading ? "Submitting..." : "SEND MESSAGE"}
                                    </button>
                                </div>
                            </form>
                        </div>

                        <div className="col-md-6 ps-md-5">
                            <div className="d-flex align-items-center bg-dark-opacity p-4 mb-4 ">
                                <i className="bi bi-geo-alt-fill display-6 me-3 text-white"></i>
                                <p className="mb-0 text-white">
                                    <b>Office  : </b> {contactSection.contact_addresses}
                                </p>
                            </div>

                            <div className="d-flex align-items-center bg-dark-opacity p-4 mb-4">
                                <i className="bi bi-envelope me-3 display-6 text-white"></i>
                                <p className="mb-0">
                                    <a
                                        href={`mailto:${contactSection.contact_mails}`}
                                        className="text-decoration-none text-white"
                                    >
                                        {contactSection.contact_mails}
                                    </a>
                                </p>
                            </div>

                            <div className="d-flex align-items-center bg-dark-opacity p-4 mb-4">
                                <i className="bi bi-telephone display-6 me-3 text-white"></i>
                                <p className="mb-0">
                                    <a
                                        href={`tel:${contactSection.contact_numbers}`}
                                        className="text-decoration-none text-white"
                                    >
                                        {contactSection.contact_numbers}
                                    </a>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className="row ">
                <div className="col-12">

                    <iframe src={contactSection.latitude} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" className='map'></iframe>

                </div>
            </section>


            <MakeAppointment data={calendlyBanner} />


            <Footer />
        </div>
    )
}

export default Contact